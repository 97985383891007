class MKSlider {
    constructor({
                    selectorSlider,
                    selectorWrapper,
                    selectorControl = null,
                    selectorPrev = null,
                    selectorNext = null,
                    selectorDots = null,
                    loop = false,
                    autoPlay = false,
                    speed = 5000,
                    step = 1,
                    start = 1,
                    view = 1,
                    media = false,
                    hoverPause = false,
                    onAfterMove = false
                }) {
        // Проверка обязательных параметров
        if (!selectorSlider || !selectorWrapper) {
            throw new Error('Обязательные параметры: selectorSlider и selectorWrapper');
        }

        // Проверка на существование элементов в DOM с использованием MK
        const slider = MK(selectorSlider);
        const wrapper = MK(selectorWrapper);

        if (!slider.exist() || !wrapper.exist()) {
            throw new Error('Элементы selectorSlider и selectorWrapper должны существовать в DOM');
        }

        // Проверка наличия элементов с классом MKSlide внутри wrapper
        this.slides = MK(selectorWrapper + " .MKSlide");

        if (!this.slides.length > 0) {
            throw new Error('Внутри элемента selectorWrapper должны быть элементы с классом MKSlide');
        }

        // Инициализация значений по умолчанию
        this.loop = loop;
        this.autoPlay = autoPlay;
        this.speed = speed;
        this.hoverPause = speed;

        // Валидация параметров view, step и start
        this.validateNumber('view', view, 1, this.slides.length);
        this.validateNumber('step', step, 1, this.slides.length);
        this.validateNumber('start', start, 1, this.slides.length);

        this.step = step;
        this.start = start;
        this.view = view;

        // Проверка и обработка media, если оно не false
        if (this.media && typeof this.media === 'object') {
            if (typeof media !== 'object') {
                throw new Error('media должно быть объектом или false');
            }
            this.validateMedia(media, this.slides.length);
        }

        // Сохраняем параметры в свойствах класса
        this.selectorSlider = selectorSlider;
        this.selectorWrapper = selectorWrapper;
        this.selectorControl = selectorControl;
        this.selectorPrev = selectorPrev;
        this.selectorNext = selectorNext;
        this.selectorDots = selectorDots;
        this.media = media;

        if(onAfterMove && typeof onAfterMove === "function") this.onAfterMove = onAfterMove;

        // Начальная инициализация
        this.init();

    }

    validateNumber(name, value, min, max) {
        if (typeof value !== 'number') {
            throw new Error(`Значение для ${name} должно быть числом`);
        }
        if (value < min || value > max) {
            throw new Error(`Значение для ${name} должно быть в пределах от ${min} до ${max}`);
        }
    }

    validateMedia(media, maxSlides) {
        for (const key in media) {

            const numericKey = Number(key);
            if (isNaN(numericKey)) {
                throw new Error('Ключи объекта media должны быть числами');
            }

            const value = media[key];
            if (typeof value.loop !== 'boolean') {
                throw new Error('Значение для ключа loop должно быть boolean');
            }

            if (typeof value.autoPlay !== 'boolean') {
                throw new Error('Значение для ключа autoPlay должно быть boolean');
            }

            if (typeof value.hoverPause !== 'boolean') {
                throw new Error('Значение для ключа autoPlay должно быть boolean');
            }

            this.validateNumber('view', value.view, 1, maxSlides);
            this.validateNumber('step', value.step, 1, maxSlides);
            this.validateNumber('start', value.start, 1, maxSlides);
        }
    }


    init() {
        // Инициализация слайдера (например, получение элементов по селекторам)
        this.slider = MK(this.selectorSlider);
        this.wrapper = MK(this.selectorWrapper);
        this.controls = this.selectorControl ? MK(this.selectorControl) : null;
        this.prevButton = this.selectorPrev ? MK(this.selectorPrev) : null;
        this.nextButton = this.selectorNext ? MK(this.selectorNext) : null;
        this.dots = this.selectorDots ? MK(this.selectorDots) : null;

        this.wrapperRect = this.wrapper.getBounds();

        this.slides.each(function (index) {
            if (MK(this).exist()) {
                let rect = MK(this).getBounds();
                MK(this).addClass("MKSlide-" + (index + 1));
                MK(this).attr("MKSlideID", "" + (index + 1));
                MK(this).attr("MK-X", "" + rect.x);
            }
        });

        if(this.dots) this.checkDots();

        this.getCurrentMedia();

        this.current.id = this.current.start;

        if(this.prevButton) {
            this.prevButton.click(() => { this.toPrev() });
        }

        if(this.nextButton) {
            this.nextButton.click(() => { this.toNext() });
        }

        if (this.dots) {
            let that = this;
            this.dots.click(function () {
                let id = Number(MK(this).attr("mkdotid"));
                if (id > 0) {
                    that.toID.bind(that)(id);
                }
            });
        }

        this.slider.css("user-select", "none");
        this.slider.swipe((event) => {
            if(event.detail.direction == "right") this.toPrev();
            if(event.detail.direction == "left") this.toNext();
        });

        let MKSlideX = MK(this.selectorWrapper + " .MKSlide-1").getBounds().x;
        let MKWrapperX = MK(this.selectorWrapper).getBounds().x;

        this.current.defaultPosition = MKSlideX - MKWrapperX;

        this.toID(this.current.start);

        if(this.current.autoPlay) {
            this.current.progress = setInterval( () => {
                this.toNext();
            }, this.speed);

        }

        MK(this.selectorWrapper).hover(
            () => { this.stop(); },
            () => { this.play(); }
        );

        if(this.controls) {
            this.controls.addClass("playing");
            this.controls.click(() => {
                if(this.controls.hasClass("playing")) {
                    MK(this.selectorWrapper).addClass("MKbyClick");
                    this.stop();
                }
                else if(this.controls.hasClass("stopped")) { this.play("click"); }
            });
        }
    }

    getCurrentMedia() {
        // Дефолтные значения
        const defaultValues = {
            step: this.step,
            start: this.start,
            loop: this.loop,
            autoPlay: this.autoPlay,
            speed: this.speed,
            view: this.view,
            hoverPause: this.hoverPause
        };

        // Инициализация this.current дефолтными значениями
        this.current = { ...defaultValues };

        // Если media равно false или его нет, оставляем только дефолтные значения
        if (this.media && typeof this.media === 'object') {
            // Получаем текущую ширину окна браузера
            const currentWidth = window.innerWidth;

            // Сортируем ключи media по убыванию
            const sortedKeys = Object.keys(this.media)
                .map(Number)
                .filter(key => !isNaN(key))
                .sort((a, b) => b - a);

            // Ищем первый подходящий ключ
            for (const key of sortedKeys) {
                if (currentWidth <= key) {
                    // Обновляем значения в this.current, если найден подходящий ключ
                    this.current = { ...this.current, ...this.media[key] };
                    break; // Останавливаемся на первом подходящем ключе
                }
            }
        }
    }

    checkDots() {
        const slidesLength = this.slides.length;
        this.dots.each(function (index) {
            if (MK(this).exist()) {
                if((index + 1) <= slidesLength) {
                    MK(this).addClass("MKDot");
                    MK(this).addClass("MKDot-" + (index + 1));
                    MK(this).attr("MKDotID", "" + (index + 1));
                }
                else MK(this).remove();
            }
        });

        this.dots = MK(this.selectorDots);
    }

    toNext() {
        if((this.current.id + this.current.step) > (this.slides.length - (this.current.view - 1))) {
            if(this.current.loop === true) this.current.id = 1;
        }
        else this.current.id = this.current.id + this.current.step;
        this.itemRect();
    }

    toPrev() {
        if((this.current.id - this.current.step) < 1) {
            if(this.current.loop === true) this.current.id = (this.slides.length - (this.current.view - 1));
        }
        else this.current.id = this.current.id - this.current.step;
        this.itemRect();
    }

    toID(id) {
        if(id > 0 && id <= this.slides.length) this.current.id = id;
        this.itemRect();
    }

    itemRect () {
        const item = Number(MK(this.selectorWrapper + " .MKSlide-" + this.current.id).attr("MK-X"));
        if(item > 0) this.current.rect = item - (this.wrapperRect.x + 0.5);
        this.move();
    }

    move() {
        if(this.current.rect < this.current.defaultPosition) this.current.rect = this.current.defaultPosition;
        this.wrapper.css("transform", "translateX(-" + (this.current.rect - this.current.defaultPosition) + "px)");
        this.update();
    }

    update () {
        const currentID = Number(this.current.id);
        const MKSlide = MK(this.selectorSlider + " .MKSlide");
        const MKDot = MK(this.selectorSlider + " .MKDot");
        const MKSlideID = MK(this.selectorSlider + " .MKSlide-" + currentID);
        const MKDotID = MK(this.selectorSlider + " .MKDot-" + currentID);

        if(currentID === 1) {
            MKDot.removeClass("dot-load");
        }

        MKSlide.removeClass("active");
        MKDot.removeClass("active");

        let first = Number(this.current.id);
        let last = (first- 1) + Number(this.current.view);
        MK(this.selectorWrapper + " .MKSlide").removeClass("MKShow");
        for (let i = first; i <= last; i++) {
            MK(this.selectorWrapper + " .MKSlide-" + i).addClass("MKShow");
        }

        setTimeout( () => {
            if(MKSlideID.exist()) {
                MKSlideID.addClass("active");
            }
            if(MKDotID.exist()) MKDotID.addClass("active");

            MKDot.each(function () {
                let id = Number(MK(this).attr("mkdotid"));
                if(id > currentID) MK(this).removeClass("dot-load");
                else MK(this).addClass("dot-load");
            });

        }, 100);

        if(typeof this.onAfterMove === "function") this.onAfterMove();
    }

    stop() {
        if(this.current.progress) clearInterval(this.current.progress);
        if(this.controls) {
            this.controls.removeClass("playing");
            this.controls.addClass("stopped");
            MK(this.selectorSlider + " .MKDot.active").addClass("this");
            MK(this.selectorSlider + " .MKDot.this").removeClass("dot-load");
            MK(this.selectorSlider + " .MKDot.this").removeClass("active");
        }
    }

    play(type = "hover") {
        if(this.current.autoPlay) {
            if(MK(this.selectorWrapper).hasClass("MKbyClick") && type == "click") {
                MK(this.selectorWrapper).removeClass("MKbyClick");
            }
            if(!MK(this.selectorWrapper).hasClass("MKbyClick")) {
                this.current.progress = setInterval(() => {
                    this.toNext();
                }, this.speed);

                if(this.controls) {
                    this.controls.removeClass("stopped");
                    this.controls.addClass("playing");
                    MK(this.selectorSlider + " .MKDot.this").addClass("active");
                    MK(this.selectorSlider + " .MKDot.active").addClass("dot-load");
                    MK(this.selectorSlider + " .MKDot.active").removeClass("this");
                }
            }
        }
    }
}
